









import { getCurrentInstance, defineComponent } from '@vue/composition-api'

import { BadgeProps } from './Badge.contracts'
import { badgeProps, useBadge } from './Badge.hooks'

/**
 * Component capable to render Badge element.
 *
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const Badge = defineComponent({
  name: 'Badge',
  props: badgeProps,

  setup (props: BadgeProps) {
    const internalInstance = getCurrentInstance()

    return useBadge(props, internalInstance)
  }
})
export default Badge
