import { Authentication, AuthServiceType, IModal, ModalType } from '@movecloser/front-core'
import { Component, Inject as VueInject, Mixins, Prop, Watch } from 'vue-property-decorator'

import { AllowedAttributes, AttributeValue } from '../../../../contexts'
import { defaultProvider, Inject, IS_MOBILE_PROVIDER_KEY } from '../../../../support'
import { ImageProps } from '../../../../dsl/atoms/Image'

import { DrawerType, IDrawer } from '../../../shared/contracts/services'
import { openAuthDrawer, UserModel } from '../../../auth/shared'
import { ProductCartMixin } from '../../../checkout/shared/mixins/product-cart.mixin'
import { ToastMixin } from '../../../shared'
import { ToastType } from '../../../shared/services'

import { Modals } from '../../config/modals'
import { translateProductVariantsToVariantsSwitch, VariantsSwitchProps } from '../../molecules/VariantsSwitch'

import { isAttribute, translateProductToProductCard } from './ProductCard.helpers'
import { ProductCardProps, ProductCardVariant, ResolvedProductCard } from './ProductCard.contracts'
import { BaseWishListMixin, IBaseWishListMixin } from '../../../wishlist/shared/mixins/base.mixin'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<ProductCardMixin>({
  name: 'ProductCardMixin',
  created (): void {
    this.resolvedProduct = translateProductToProductCard(this.product, false)
    this.setActiveVariant()
  },
  mounted (): void {
    this.checkIsFavourite()
  }
})
export class ProductCardMixin extends Mixins<ProductCartMixin, ToastMixin, IBaseWishListMixin>(ProductCartMixin, ToastMixin, BaseWishListMixin) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(AuthServiceType, false)
  private readonly authService?: Authentication<UserModel>

  @Inject(DrawerType, false)
  protected readonly drawerConnector?: IDrawer

  @Inject(ModalType)
  protected readonly modalConnector!: IModal

  @Prop({ type: String, required: false, default: null })
  protected readonly buttonIcon!: string | null

  @Prop({ type: Boolean, required: false, default: false })
  public isGratis!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public isLazy!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public isWishlist!: boolean

  @Prop({ type: String, required: false, default: 'medium' })
  public readonly modalSize?: string

  @Prop({ type: Boolean, required: false, default: true })
  public readonly useDrawer?: boolean

  @Prop({ type: Object, required: true })
  public readonly product!: ProductCardProps

  @Prop({ type: Boolean, required: false, default: false })
  public readonly shouldAnimate?: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly usePreviousPriceAsRegular?: boolean

  /**
   * Determines variant which is currently displayed.
   */
  public activeVariant: ProductCardVariant | undefined | null = null

  /**
   * Determines whether variant is in favourites
   */
  public isFavourite: boolean = false

  public isFavouriteLoading: boolean = false

  public isLoading: boolean = false

  public itemAdded: boolean = false

  public resolvedProduct: ResolvedProductCard | null = null

  /**
   * Determines whether product has discount.
   */
  public get hasDiscount (): boolean {
    if (!this.activeVariant) {
      return false
    }

    return this.activeVariant.price.regularPrice > this.activeVariant.price.finalPrice
  }

  public get hasSellableQuantity (): boolean {
    if (typeof this.activeVariant === 'undefined' || !this.activeVariant) {
      return false
    }

    return this.activeVariant.sellableQuantity > 0
  }

  /**
   * Determines whether product has variants.
   */
  public get hasVariants (): boolean {
    return this.variants.length > 0
  }

  /**
   * Product's image.
   */
  public get productImage (): ImageProps | undefined {
    if (typeof this.activeVariant === 'undefined' || !this.activeVariant || !this.resolvedProduct) {
      return
    }

    const activeVariantHasImages = Array.isArray(this.activeVariant.images) && this.activeVariant.images.length > 0
    if (activeVariantHasImages) {
      return this.activeVariant.images[0]
    }

    const variantWithImages = Object.values(this.resolvedProduct.variants).find((variant) => {
      return variant.images.length > 0
    })

    if (variantWithImages) {
      return variantWithImages.images[0]
    }

    return { src: '', alt: '' }
  }

  public get productLine (): string | undefined {
    return this.getAttribute<string>(AllowedAttributes.ProductLine)
  }

  /**
   * Determines whether component has everything to be rendered.
   */
  public get shouldRender (): boolean {
    return this.hasVariants && typeof this.activeVariant !== 'undefined'
  }

  /**
   * Translated (mapped) variants.
   */
  public translateProductVariantsToVariantsSwitch (type = 'color'): VariantsSwitchProps['variants'] {
    return translateProductVariantsToVariantsSwitch(this.product, type)
  }

  public get variantUrlPath (): string {
    if (this.activeVariant && Object.values(this.product.variants).length > 1) {
      return this.activeVariant.link
    }

    return this.product.urlPath || ''
  }

  /**
   * Determines product variants.
   */
  public get variants (): ProductCardVariant[] {
    return this.resolvedProduct ? Object.values(this.resolvedProduct.variants) : []
  }

  /**
   * Handles adding product to cart.
   */
  public async onAddToCart (disableAction: boolean = false): Promise<void> {
    if (!this.cartService) {
      return
    }

    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isLoading = true
    this.itemAdded = true

    try {
      await this.addToCart(
        { ...this.activeVariant, description: this.productLine },
        1,
        true,
        this.modalSize,
        this.isMobile() ? this.useDrawer : false,
        disableAction
      )
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.isLoading = false
    }
  }

  /**
   * Opens add review modal
   */
  public openProductReviewsModal (): void {
    if (!this.modalConnector) {
      return
    }

    let color = ''
    if (this.activeVariant && this.activeVariant?.identifier.type === 'color') {
      color = this.activeVariant.identifier.value
    }

    this.modalConnector.open(Modals.ProductReviewsModal, {
      color,
      description: this.activeVariant?.name,
      location: this.activeVariant?.link,
      rate: this.activeVariant?.rating,
      sku: this.activeVariant?.sku,
      title: this.activeVariant?.attributes.productLine,
      variants: this.translateProductVariantsToVariantsSwitch('color')
    })
  }

  /**
   * Handles update:model of variant switchers.
   */
  public onVariantSwitchUpdate (slug: string): void {
    this.setActiveVariant(slug)
  }

  /**
   * Sets the active variant.
   */
  public setActiveVariant (slug?: string): void {
    if (!this.resolvedProduct) {
      return
    }

    const variants = this.resolvedProduct.variants

    if (slug) {
      this.activeVariant = variants.find((product) => product.identifier.color === slug)
    } else {
      this.activeVariant = variants[0]
    }
    this.checkIsFavourite()
  }

  /**
   * Changes (toggles) is favourite state of current variant
   */
  public async toggleFavourite (): Promise<void> {
    if (!this.isServiceAvailable) {
      return
    }

    if (!this.isWaitingForAuth && !this.authService?.check() && this.drawerConnector) {
      openAuthDrawer(this.drawerConnector)
      return
    }

    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isFavouriteLoading = true
    try {
      if (!this.isFavourite) {
        await this.add({
          quantity: 1,
          sku: this.activeVariant.sku
        })
        this.isFavourite = true
      } else {
        await this.remove(this.activeVariant.sku)
        this.isFavourite = false
      }
    } catch (e) {
      this.notify((e as Error).message, ToastType.Danger)
    } finally {
      this.isFavouriteLoading = false
    }
  }

  /**
   * Checks the current variant if it is present in favourites list.
   */
  protected checkIsFavourite (): void {
    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    this.isFavourite = this.isInWishlist(this.activeVariant.sku)
  }

  /**
   * Gets the attribute by key
   *
   * @param attribute - attribute key
   */
  protected getAttribute<R extends AttributeValue | AttributeValue[]> (attribute: string): R | undefined {
    if (!this.activeVariant || typeof this.activeVariant === 'undefined') {
      return
    }

    if (!isAttribute(attribute)) {
      return undefined
    }

    return attribute in this.activeVariant.attributes
      ? this.activeVariant.attributes[attribute] as R : undefined
  }

  protected notify (message: string, level: ToastType): void {
    this.showToast(message, level)
  }

  @Watch('wishlist')
  private onWishlist (): void {
    if (this.wishlist) {
      this.checkIsFavourite()
    }
  }
}

export default ProductCardMixin
